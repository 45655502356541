import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/5.jpg)`,
                        }}
                    >
                        <h1>Trituradora</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                La trituradora Zion Tech es capaz de triturar en húmedo, lo que provoca un lavado por
                                fricción en escamas de PET. Mientras tanto, el lavado con agua enfría las cuchillas de
                                la trituradora y reduce la temperatura de la cámara de trituración y la abrasión de la
                                cuchilla, reduce el polvo de PET y la pérdida de escamas de PET, este proceso crea una
                                mejor condición para el PET copos de lavado en caliente.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1. La trituradora adopta la última tecnología de corte en forma de V, la rotación del
                                eje transporta las botellas de PET al centro de la cámara de la trituradora, evita que
                                estas botellas de entrada se peguen a la pared lateral de la cámara y aumenta la vida
                                útil de la cámara de la trituradora al reducir la abrasión.
                            </p>
                            <p style={{color: '#000'}}>
                                2. La cuchilla de la trituradora está hecha de aleación de acero importada, que es
                                resistente al desgaste, de alta dureza y mayor vida útil. Están disponibles para ser
                                afiladas y reutilizadas.
                            </p>
                            <p style={{color: '#000'}}>
                                3. La cámara de la trituradora está hecha de acero de alta dureza. Se procesa con un
                                torno CNC que tiene la ventaja de un gran espesor, alta resistencia, fuerte resistencia
                                al desgaste, sin contaminación y una larga vida útil. Además, es conveniente para el
                                mantenimiento diario. .
                            </p>
                            <p style={{color: '#000'}}>
                                4. Una cortina de seguridad está equipada en la tolva de entrada de la trituradora, que
                                evita que las escamas de PET salpiquen cuando la trituradora está en funcionamiento.
                            </p>
                            <p style={{color: '#000'}}>
                                5. La polea trituradora está conectada al eje de transmisión a través de una correa en
                                forma de V, cuenta con un funcionamiento equilibrado, una coordinación precisa, además,
                                es fácil de reparar y mantener.
                            </p>
                            <p style={{color: '#000'}}>
                                6. La tolva de alimentación y el marco de la criba son operados por un dispositivo
                                hidráulico.
                            </p>
                            <p style={{color: '#000'}}>
                                7. Las cuchillas rotativas se pueden completar con la calibración de antemano con
                                herramientas fuera de la cámara, es más conveniente y seguro ".
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                500-4000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
